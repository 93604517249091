"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Pararasan {
    constructor(id, name) {
        this.id = id;
        this.name = name;
        Object.freeze(this);
    }
    static get values() { return _C_VAL; }
    static get LAKU_PANDITA_SAKTI() { return _C_VAL[0]; }
    static get ARAS_TUDING() { return _C_VAL[1]; }
    static get ARAS_KEMBANG() { return _C_VAL[2]; }
    static get LAKU_BINTANG() { return _C_VAL[3]; }
    static get LAKU_BULAN() { return _C_VAL[4]; }
    static get LAKU_SURYA() { return _C_VAL[5]; }
    static get LAKU_AIR() { return _C_VAL[6]; }
    static get LAKU_BUMI() { return _C_VAL[7]; }
    static get LAKU_API() { return _C_VAL[8]; }
    static get LAKU_ANGIN() { return _C_VAL[9]; }
}
exports.Pararasan = Pararasan;
const _C_VAL = [
    new Pararasan(0, "Laku Pandita Sakti"),
    new Pararasan(1, "Aras Tuding"),
    new Pararasan(2, "Aras Kembang"),
    new Pararasan(3, "Laku Bintang"),
    new Pararasan(4, "Laku Bulan"),
    new Pararasan(5, "Laku Surya"),
    new Pararasan(6, "Laku Air"),
    new Pararasan(7, "Laku Bumi"),
    new Pararasan(8, "Laku Api"),
    new Pararasan(9, "Laku Angin"),
];
Object.freeze(_C_VAL);
