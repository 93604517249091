"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class PancaWara {
    constructor(id, urip, kupih, name) {
        this.id = id;
        this.urip = urip;
        this.kupih = kupih;
        this.name = name;
        Object.freeze(this);
    }
    static get values() { return _C_VAL; }
    static get PAING() { return _C_VAL[0]; }
    static get PON() { return _C_VAL[1]; }
    static get WAGE() { return _C_VAL[2]; }
    static get KLIWON() { return _C_VAL[3]; }
    static get UMANIS() { return _C_VAL[4]; }
}
exports.PancaWara = PancaWara;
const _C_VAL = [
    new PancaWara(0, 9, 3, "Paing"),
    new PancaWara(1, 7, 4, "Pon"),
    new PancaWara(2, 4, 5, "Wage"),
    new PancaWara(3, 8, 1, "Kliwon"),
    new PancaWara(4, 5, 2, "Umanis"),
];
Object.freeze(_C_VAL);
