"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class WatekAlit {
    constructor(id, name) {
        this.id = id;
        this.name = name;
        Object.freeze(this);
    }
    static get values() { return _C_VAL; }
    static get LINTAH() { return _C_VAL[0]; }
    static get ULER() { return _C_VAL[1]; }
    static get GAJAH() { return _C_VAL[2]; }
    static get LEMBU() { return _C_VAL[3]; }
}
exports.WatekAlit = WatekAlit;
const _C_VAL = [
    new WatekAlit(0, "Lintah"),
    new WatekAlit(1, "Uler"),
    new WatekAlit(2, "Gajah"),
    new WatekAlit(3, "Lembu"),
];
Object.freeze(_C_VAL);
