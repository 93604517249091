"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class WatekMadya {
    constructor(id, name) {
        this.id = id;
        this.name = name;
        Object.freeze(this);
    }
    static get values() { return _C_VAL; }
    static get WONG() { return _C_VAL[0]; }
    static get GAJAH() { return _C_VAL[1]; }
    static get WATU() { return _C_VAL[2]; }
    static get BUTA() { return _C_VAL[3]; }
    static get SUKU() { return _C_VAL[4]; }
}
exports.WatekMadya = WatekMadya;
const _C_VAL = [
    new WatekMadya(0, "Wong"),
    new WatekMadya(1, "Gajah"),
    new WatekMadya(2, "Watu"),
    new WatekMadya(3, "Buta"),
    new WatekMadya(4, "Suku"),
];
Object.freeze(_C_VAL);
