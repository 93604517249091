"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class SaptaWara {
    constructor(id, urip, kertaAji, kupih, name) {
        this.id = id;
        this.urip = urip;
        this.kertaAji = kertaAji;
        this.kupih = kupih;
        this.name = name;
        Object.freeze(this);
    }
    static get values() { return _C_VAL; }
    static get REDITE() { return _C_VAL[0]; }
    static get SOMA() { return _C_VAL[1]; }
    static get ANGGARA() { return _C_VAL[2]; }
    static get BUDA() { return _C_VAL[3]; }
    static get WRASPATI() { return _C_VAL[4]; }
    static get SUKRA() { return _C_VAL[5]; }
    static get SANISCARA() { return _C_VAL[6]; }
}
exports.SaptaWara = SaptaWara;
const _C_VAL = [
    new SaptaWara(0, 5, 6, 3, "Redite"),
    new SaptaWara(1, 4, 4, 4, "Soma"),
    new SaptaWara(2, 3, 3, 5, "Anggara"),
    new SaptaWara(3, 7, 6, 6, "Buda"),
    new SaptaWara(4, 8, 5, 7, "Wraspati"),
    new SaptaWara(5, 6, 7, 1, "Sukra"),
    new SaptaWara(6, 9, 8, 2, "Saniscara"),
];
Object.freeze(_C_VAL);
