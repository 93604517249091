"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class DwiWara {
    constructor(id, urip, name) {
        this.id = id;
        this.urip = urip;
        this.name = name;
        Object.freeze(this);
    }
    static get values() { return _C_VAL; }
    static get MENGA() { return _C_VAL[0]; }
    static get PEPET() { return _C_VAL[1]; }
}
exports.DwiWara = DwiWara;
const _C_VAL = [
    new DwiWara(0, 5, "Menga"),
    new DwiWara(1, 4, "Pepet"),
];
Object.freeze(_C_VAL);
