"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class SadWara {
    constructor(id, urip, name) {
        this.id = id;
        this.urip = urip;
        this.name = name;
        Object.freeze(this);
    }
    static get values() { return _C_VAL; }
    static get TUNGLEH() { return _C_VAL[0]; }
    static get ARYANG() { return _C_VAL[1]; }
    static get URUKUNG() { return _C_VAL[2]; }
    static get PANIRON() { return _C_VAL[3]; }
    static get WAS() { return _C_VAL[4]; }
    static get MAULU() { return _C_VAL[5]; }
}
exports.SadWara = SadWara;
const _C_VAL = [
    new SadWara(0, 7, "Tungleh"),
    new SadWara(1, 6, "Aryang"),
    new SadWara(2, 5, "Urukung"),
    new SadWara(3, 8, "Paniron"),
    new SadWara(4, 9, "Was"),
    new SadWara(5, 3, "Maulu"),
];
Object.freeze(_C_VAL);
