"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Ingkel {
    constructor(id, name) {
        this.id = id;
        this.name = name;
        Object.freeze(this);
    }
    static get values() { return _C_VAL; }
    static get WONG() { return _C_VAL[0]; }
    static get SATO() { return _C_VAL[1]; }
    static get MINA() { return _C_VAL[2]; }
    static get MANUK() { return _C_VAL[3]; }
    static get TARU() { return _C_VAL[4]; }
    static get BUKU() { return _C_VAL[5]; }
}
exports.Ingkel = Ingkel;
const _C_VAL = [
    new Ingkel(0, "Wong"),
    new Ingkel(1, "Sato"),
    new Ingkel(2, "Mina"),
    new Ingkel(3, "Manuk"),
    new Ingkel(4, "Taru"),
    new Ingkel(5, "Buku"),
];
Object.freeze(_C_VAL);
