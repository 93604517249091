"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class DasaWara {
    constructor(id, urip, name) {
        this.id = id;
        this.urip = urip;
        this.name = name;
        Object.freeze(this);
    }
    static get values() { return _C_VAL; }
    static get PANDITA() { return _C_VAL[0]; }
    static get PATI() { return _C_VAL[1]; }
    static get SUKA() { return _C_VAL[2]; }
    static get DUKA() { return _C_VAL[3]; }
    static get SRI() { return _C_VAL[4]; }
    static get MANUH() { return _C_VAL[5]; }
    static get MANUSA() { return _C_VAL[6]; }
    static get RAJA() { return _C_VAL[7]; }
    static get DEWA() { return _C_VAL[8]; }
    static get RAKSASA() { return _C_VAL[9]; }
}
exports.DasaWara = DasaWara;
const _C_VAL = [
    new DasaWara(0, 5, "Pandita"),
    new DasaWara(1, 7, "Pati"),
    new DasaWara(2, 10, "Suka"),
    new DasaWara(3, 4, "Duka"),
    new DasaWara(4, 6, "Sri"),
    new DasaWara(5, 2, "Manuh"),
    new DasaWara(6, 3, "Manusa"),
    new DasaWara(7, 8, "Raja"),
    new DasaWara(8, 9, "Dewa"),
    new DasaWara(9, 1, "Raksasa"),
];
Object.freeze(_C_VAL);
