"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class SasihDayInfo {
    constructor(id, refId, name) {
        this.id = id;
        this.refId = refId;
        this.name = name;
        Object.freeze(this);
    }
    static get values() { return _C_VAL; }
    static get PENANGGAL() { return _C_VAL[0]; }
    static get PANGELONG() { return _C_VAL[1]; }
    static get PURNAMA() { return _C_VAL[2]; }
    static get TILEM() { return _C_VAL[3]; }
    get reference() { return _C_VAL[this.refId]; }
}
exports.SasihDayInfo = SasihDayInfo;
const _C_VAL = [
    new SasihDayInfo(0, 0, "Penanggal"),
    new SasihDayInfo(1, 1, "Pangelong"),
    new SasihDayInfo(2, 0, "Purnama"),
    new SasihDayInfo(3, 1, "Tilem"),
];
Object.freeze(_C_VAL);
