"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class CaturWara {
    constructor(id, urip, name) {
        this.id = id;
        this.urip = urip;
        this.name = name;
        Object.freeze(this);
    }
    static get values() { return _C_VAL; }
    static get SRI() { return _C_VAL[0]; }
    static get LABA() { return _C_VAL[1]; }
    static get JAYA() { return _C_VAL[2]; }
    static get MENALA() { return _C_VAL[3]; }
}
exports.CaturWara = CaturWara;
const _C_VAL = [
    new CaturWara(0, 6, "Sri"),
    new CaturWara(1, 5, "Laba"),
    new CaturWara(2, 1, "Jaya"),
    new CaturWara(3, 8, "Menala"),
];
Object.freeze(_C_VAL);
