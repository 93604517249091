"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Filter {
    constructor() {
        this.ekaWara = undefined;
        this.dwiWara = undefined;
        this.triWara = undefined;
        this.caturWara = undefined;
        this.pancaWara = undefined;
        this.sadWara = undefined;
        this.saptaWara = undefined;
        this.astaWara = undefined;
        this.sangaWara = undefined;
        this.dasaWara = undefined;
        this.ingkel = undefined;
        this.jejepan = undefined;
        this.lintang = undefined;
        this.pancaSuda = undefined;
        this.pararasan = undefined;
        this.watekAlit = undefined;
        this.watekMadya = undefined;
        this.rakam = undefined;
        this.ekaJalaRsi = undefined;
        this.wuku = undefined;
        this.sasih = undefined;
        this.sasihDayInfo = undefined;
        this.sasihDay = undefined;
        this.saka = undefined;
        this.pratithiSamutPada = undefined;
        Object.seal(this);
    }
}
exports.Filter = Filter;
