"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Jejepan {
    constructor(id, name) {
        this.id = id;
        this.name = name;
        Object.freeze(this);
    }
    static get values() { return _C_VAL; }
    static get MINA() { return _C_VAL[0]; }
    static get TARU() { return _C_VAL[1]; }
    static get SATO() { return _C_VAL[2]; }
    static get PATRA() { return _C_VAL[3]; }
    static get WONG() { return _C_VAL[4]; }
    static get PAKSI() { return _C_VAL[5]; }
}
exports.Jejepan = Jejepan;
const _C_VAL = [
    new Jejepan(0, "Mina"),
    new Jejepan(1, "Taru"),
    new Jejepan(2, "Sato"),
    new Jejepan(3, "Patra"),
    new Jejepan(4, "Wong"),
    new Jejepan(5, "Paksi"),
];
Object.freeze(_C_VAL);
