"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class TriWara {
    constructor(id, urip, name) {
        this.id = id;
        this.urip = urip;
        this.name = name;
        Object.freeze(this);
    }
    static get values() { return _C_VAL; }
    static get PASAH() { return _C_VAL[0]; }
    static get BETENG() { return _C_VAL[1]; }
    static get KAJENG() { return _C_VAL[2]; }
}
exports.TriWara = TriWara;
const _C_VAL = [
    new TriWara(0, 9, "Pasah"),
    new TriWara(1, 4, "Beteng"),
    new TriWara(2, 7, "Kajeng"),
];
Object.freeze(_C_VAL);
