"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class EkaWara {
    constructor(id, urip, name) {
        this.id = id;
        this.urip = urip;
        this.name = name;
        Object.freeze(this);
    }
    static get values() { return _C_VAL; }
    static get VOID() { return _C_VAL[0]; }
    static get LUANG() { return _C_VAL[1]; }
}
exports.EkaWara = EkaWara;
const _C_VAL = [
    new EkaWara(0, 0, ""),
    new EkaWara(1, 1, "Luang"),
];
Object.freeze(_C_VAL);
