"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Rakam {
    constructor(id, name) {
        this.id = id;
        this.name = name;
        Object.freeze(this);
    }
    static get values() { return _C_VAL; }
    static get NUJU_PATI() { return _C_VAL[0]; }
    static get KALA_TINANTANG() { return _C_VAL[1]; }
    static get DEMANG_KANDHURUWAN() { return _C_VAL[2]; }
    static get SANGGAR_WARINGIN() { return _C_VAL[3]; }
    static get MANTRI_SINAROJA() { return _C_VAL[4]; }
    static get MACAN_KATAWAN() { return _C_VAL[5]; }
}
exports.Rakam = Rakam;
const _C_VAL = [
    new Rakam(0, "Nuju Pati"),
    new Rakam(1, "Kala Tinantang"),
    new Rakam(2, "Demang Kandhuruwan"),
    new Rakam(3, "Sanggar Waringin"),
    new Rakam(4, "Mantri Sinaroja"),
    new Rakam(5, "Macan Katawan"),
];
Object.freeze(_C_VAL);
