"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class SangaWara {
    constructor(id, urip, name) {
        this.id = id;
        this.urip = urip;
        this.name = name;
        Object.freeze(this);
    }
    static get values() { return _C_VAL; }
    static get DANGU() { return _C_VAL[0]; }
    static get JANGUR() { return _C_VAL[1]; }
    static get GIGIS() { return _C_VAL[2]; }
    static get NOHAN() { return _C_VAL[3]; }
    static get OGAN() { return _C_VAL[4]; }
    static get ERANGAN() { return _C_VAL[5]; }
    static get URUNGAN() { return _C_VAL[6]; }
    static get TULUS() { return _C_VAL[7]; }
    static get DADI() { return _C_VAL[8]; }
}
exports.SangaWara = SangaWara;
const _C_VAL = [
    new SangaWara(0, 5, "Dangu"),
    new SangaWara(1, 8, "Jangur"),
    new SangaWara(2, 9, "Gigis"),
    new SangaWara(3, 3, "Nohan"),
    new SangaWara(4, 7, "Ogan"),
    new SangaWara(5, 1, "Erangan"),
    new SangaWara(6, 4, "Urungan"),
    new SangaWara(7, 6, "Tulus"),
    new SangaWara(8, 8, "Dadi"),
];
Object.freeze(_C_VAL);
