"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class PratithiSamutPada {
    constructor(id, name) {
        this.id = id;
        this.name = name;
        Object.freeze(this);
    }
    static get values() { return _C_VAL; }
    static get TRESNA() { return _C_VAL[0]; }
    static get UPADANA() { return _C_VAL[1]; }
    static get BHAWA() { return _C_VAL[2]; }
    static get JATI() { return _C_VAL[3]; }
    static get JARAMARANA() { return _C_VAL[4]; }
    static get AWIDYA() { return _C_VAL[5]; }
    static get SASKARA() { return _C_VAL[6]; }
    static get WIDNYANA() { return _C_VAL[7]; }
    static get NAMARUPA() { return _C_VAL[8]; }
    static get SADAYATANA() { return _C_VAL[9]; }
    static get SEPARSA() { return _C_VAL[10]; }
    static get WEDANA() { return _C_VAL[11]; }
}
exports.PratithiSamutPada = PratithiSamutPada;
const _C_VAL = [
    new PratithiSamutPada(0, "Tresna"),
    new PratithiSamutPada(1, "Upadana"),
    new PratithiSamutPada(2, "Bhawa"),
    new PratithiSamutPada(3, "Jati"),
    new PratithiSamutPada(4, "Jaramarana"),
    new PratithiSamutPada(5, "Awidya"),
    new PratithiSamutPada(6, "Saskara"),
    new PratithiSamutPada(7, "Widnyana"),
    new PratithiSamutPada(8, "Namarupa"),
    new PratithiSamutPada(9, "Sadayatana"),
    new PratithiSamutPada(10, "Separsa"),
    new PratithiSamutPada(11, "Wedana"),
];
Object.freeze(_C_VAL);
