"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const dateAdd = require("date-fns/add_days");
const dateCompare = require("date-fns/compare_asc");
const dateDiff = require("date-fns/difference_in_calendar_days");
const AstaWara_1 = require("./AstaWara");
exports.AstaWara = AstaWara_1.AstaWara;
const BalineseDateUtil_1 = require("./BalineseDateUtil");
exports.BalineseDateUtil = BalineseDateUtil_1.BalineseDateUtil;
const CaturWara_1 = require("./CaturWara");
exports.CaturWara = CaturWara_1.CaturWara;
const DasaWara_1 = require("./DasaWara");
exports.DasaWara = DasaWara_1.DasaWara;
const DwiWara_1 = require("./DwiWara");
exports.DwiWara = DwiWara_1.DwiWara;
const EkaJalaRsi_1 = require("./EkaJalaRsi");
exports.EkaJalaRsi = EkaJalaRsi_1.EkaJalaRsi;
const EkaWara_1 = require("./EkaWara");
exports.EkaWara = EkaWara_1.EkaWara;
const Filter_1 = require("./Filter");
exports.Filter = Filter_1.Filter;
const Ingkel_1 = require("./Ingkel");
exports.Ingkel = Ingkel_1.Ingkel;
const Pivot_1 = require("./internal/Pivot");
const Jejepan_1 = require("./Jejepan");
exports.Jejepan = Jejepan_1.Jejepan;
const Lintang_1 = require("./Lintang");
exports.Lintang = Lintang_1.Lintang;
const PancaSuda_1 = require("./PancaSuda");
exports.PancaSuda = PancaSuda_1.PancaSuda;
const PancaWara_1 = require("./PancaWara");
exports.PancaWara = PancaWara_1.PancaWara;
const Pararasan_1 = require("./Pararasan");
exports.Pararasan = Pararasan_1.Pararasan;
const PratithiSamutPada_1 = require("./PratithiSamutPada");
exports.PratithiSamutPada = PratithiSamutPada_1.PratithiSamutPada;
const Rahinan_1 = require("./Rahinan");
exports.Rahinan = Rahinan_1.Rahinan;
const Rakam_1 = require("./Rakam");
exports.Rakam = Rakam_1.Rakam;
const SadWara_1 = require("./SadWara");
exports.SadWara = SadWara_1.SadWara;
const SangaWara_1 = require("./SangaWara");
exports.SangaWara = SangaWara_1.SangaWara;
const SaptaWara_1 = require("./SaptaWara");
exports.SaptaWara = SaptaWara_1.SaptaWara;
const Sasih_1 = require("./Sasih");
exports.Sasih = Sasih_1.Sasih;
const SasihDayInfo_1 = require("./SasihDayInfo");
exports.SasihDayInfo = SasihDayInfo_1.SasihDayInfo;
const TriWara_1 = require("./TriWara");
exports.TriWara = TriWara_1.TriWara;
const WatekAlit_1 = require("./WatekAlit");
exports.WatekAlit = WatekAlit_1.WatekAlit;
const WatekMadya_1 = require("./WatekMadya");
exports.WatekMadya = WatekMadya_1.WatekMadya;
const Wuku_1 = require("./Wuku");
exports.Wuku = Wuku_1.Wuku;
class BalineseDate {
    constructor(date = new Date()) {
        this.date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        Object.freeze(this.date);
        const pivot = _F_BEST_PIVOT(this.date);
        const pawukonDay = _F_PAWUKON_DAY(pivot, this.date);
        this.wuku = Wuku_1.Wuku.values[Math.floor(pawukonDay / 7)];
        this.triWara = TriWara_1.TriWara.values[pawukonDay % 3];
        this.pancaWara = PancaWara_1.PancaWara.values[pawukonDay % 5];
        this.sadWara = SadWara_1.SadWara.values[pawukonDay % 6];
        this.saptaWara = SaptaWara_1.SaptaWara.values[pawukonDay % 7];
        this.jejepan = Jejepan_1.Jejepan.values[pawukonDay % 6];
        this.lintang = Lintang_1.Lintang.values[pawukonDay % 35];
        this.pancaSuda = PancaSuda_1.PancaSuda.values[(this.saptaWara.kertaAji + this.pancaWara.urip) % 7];
        this.rakam = Rakam_1.Rakam.values[(this.saptaWara.kupih + this.pancaWara.kupih) % 6];
        this.ekaJalaRsi = EkaJalaRsi_1.EkaJalaRsi.values[_C_EJR_MAP[pawukonDay]];
        this.ingkel = Ingkel_1.Ingkel.values[this.wuku.id % 6];
        const urip = this.pancaWara.urip + this.saptaWara.urip;
        this.ekaWara = EkaWara_1.EkaWara.values[urip % 2];
        this.dwiWara = DwiWara_1.DwiWara.values[urip % 2];
        this.dasaWara = DasaWara_1.DasaWara.values[urip % 10];
        this.watekAlit = WatekAlit_1.WatekAlit.values[urip % 4];
        this.watekMadya = WatekMadya_1.WatekMadya.values[urip % 5];
        this.pararasan = Pararasan_1.Pararasan.values[urip % 10];
        this.caturWara = _F_CALC_CATURWARA(pawukonDay);
        this.astaWara = _F_CALC_ASTAWARA(pawukonDay);
        this.sangaWara = _F_CALC_SANGAWARA(pawukonDay);
        const resSasih = _F_CALC_SASIH_INFO(pivot, this.date);
        const resSasihDay = _F_CALC_SASIH_DAY(pivot, this.date);
        this.saka = resSasih[0];
        this.sasih = _F_CALC_SASIH(resSasih);
        this.sasihDayInfo = _F_CALC_SASIH_DAY_INFO(resSasihDay, this.sasih, this.saka);
        if (resSasihDay[2] === 1) {
            this.sasihDay = [resSasihDay[0], ((resSasihDay[0] === 15) ? 1 : resSasihDay[0] + 1)];
        }
        else {
            this.sasihDay = [resSasihDay[0]];
        }
        Object.freeze(this.sasihDay);
        this.pratithiSamutPada = _F_CALC_PRATITHI_S_P(this.sasihDay, this.sasihDayInfo, this.sasih, this.date);
        Object.freeze(this);
    }
}
exports.BalineseDate = BalineseDate;
const _C_DAY_PAWUKON = 210;
const _C_DAY_NGUNARATRI = 63;
const _C_PANGALANTAKA_PAING = new Date(2000, 0, 6);
Object.freeze(_C_PANGALANTAKA_PAING);
const _C_SK_START = new Date(1993, 0, 24);
Object.freeze(_C_SK_START);
const _C_SK_END = new Date(2003, 0, 3);
Object.freeze(_C_SK_END);
const _C_EJR_MAP = Object.freeze([23, 7, 17, 7, 23, 23, 17, 9, 7, 13, 26, 24, 23, 20, 13, 7, 13, 25, 19, 6, 2,
    14, 26, 17, 20, 25, 22, 0, 10, 6, 15, 23, 7, 17, 23, 17, 25, 5, 23, 2, 2, 2, 12, 12, 5, 14, 12, 26, 26, 1,
    23, 23, 15, 25, 15, 6, 9, 25, 18, 25, 11, 15, 21, 25, 25, 12, 0, 17, 13, 0, 15, 23, 12, 7, 16, 25, 18, 24,
    12, 12, 6, 7, 6, 26, 7, 6, 12, 7, 25, 2, 12, 25, 25, 14, 15, 26, 7, 12, 20, 7, 6, 25, 25, 6, 13, 25, 17, 13,
    23, 6, 26, 20, 25, 25, 23, 7, 18, 18, 17, 7, 17, 7, 5, 26, 17, 6, 9, 12, 12, 13, 25, 18, 18, 6, 2, 25, 25,
    2, 25, 17, 20, 14, 27, 23, 17, 8, 25, 17, 6, 17, 7, 3, 15, 18, 25, 2, 7, 13, 25, 20, 7, 15, 15, 23, 7, 8,
    24, 2, 12, 9, 24, 24, 17, 24, 20, 7, 12, 12, 14, 18, 25, 20, 5, 18, 5, 20, 26, 12, 23, 18, 17, 17, 25, 15,
    2, 24, 4, 2, 23, 25, 18, 25, 20, 14, 4, 2, 25, 7, 25, 17]);
const _F_MOD = (a, b) => ((a % b) + b) % b;
const _F_DELTA_D = (a, b) => dateDiff(b, a);
const _F_BEST_PIVOT = (date) => {
    return dateCompare(date, _C_PANGALANTAKA_PAING) < 0 ? Pivot_1.Pivot.PIVOT_1971 : Pivot_1.Pivot.PIVOT_2000;
};
const _F_PAWUKON_DAY = (pivot, date) => {
    return _F_MOD(pivot.pawukonDay + _F_DELTA_D(pivot.date, date), _C_DAY_PAWUKON);
};
const _F_CALC_CATURWARA = (pawukonDay) => {
    if (pawukonDay < 71) {
        return CaturWara_1.CaturWara.values[pawukonDay % 4];
    }
    else if (pawukonDay > 72) {
        return CaturWara_1.CaturWara.values[(pawukonDay - 2) % 4];
    }
    else {
        return CaturWara_1.CaturWara.JAYA;
    }
};
const _F_CALC_ASTAWARA = (pawukonDay) => {
    if (pawukonDay < 71) {
        return AstaWara_1.AstaWara.values[pawukonDay % 8];
    }
    else if (pawukonDay > 72) {
        return AstaWara_1.AstaWara.values[(pawukonDay - 2) % 8];
    }
    else {
        return AstaWara_1.AstaWara.KALA;
    }
};
const _F_CALC_SANGAWARA = (pawukonDay) => {
    if (pawukonDay > 3) {
        return SangaWara_1.SangaWara.values[(pawukonDay - 3) % 9];
    }
    else {
        return SangaWara_1.SangaWara.DANGU;
    }
};
const _F_CALC_SASIH_INFO = (pivot, date) => {
    const res = new Array(3);
    const ptime = pivot.date;
    const dayDiff = _F_DELTA_D(ptime, date);
    const daySkip = Math.ceil(dayDiff / _C_DAY_NGUNARATRI);
    const dayTotal = pivot.sasihDay + dayDiff + daySkip;
    const pivotOffset = (pivot.sasihDay === 0 && pivot.ngunaRatriDay === 0) ? 0 : 1;
    let totalSasih = Math.ceil(dayTotal / 30) - pivotOffset;
    let currentSasih = pivot.sasih.id;
    let currentSaka = pivot.saka - (currentSasih === Sasih_1.Sasih.KADASA.id ? 1 : 0);
    let nampihCount = pivot.isNampihSasih ? 1 : 0;
    let inSK = false;
    if (dateCompare(ptime, _C_SK_START) >= 0 && dateCompare(ptime, _C_SK_END) < 0) {
        inSK = true;
    }
    while (totalSasih !== 0) {
        if (dayDiff >= 0) {
            if (nampihCount === 0 || nampihCount === 2) {
                nampihCount = 0;
                currentSasih = _F_MOD(currentSasih + 1, 12);
            }
            totalSasih = totalSasih - 1;
            if (currentSasih === Sasih_1.Sasih.KADASA.id && nampihCount === 0) {
                currentSaka = currentSaka + 1;
            }
            if (currentSasih === Sasih_1.Sasih.KAWOLU.id && currentSaka === 1914) {
                inSK = true;
            }
            else if (currentSasih === Sasih_1.Sasih.KAWOLU.id && currentSaka === 1924) {
                inSK = false;
            }
        }
        else if (dayDiff < 0) {
            if (nampihCount === 0 || nampihCount === 2) {
                nampihCount = 0;
                currentSasih = _F_MOD(currentSasih - 1, 12);
            }
            totalSasih = totalSasih + 1;
            if (currentSasih === Sasih_1.Sasih.KASANGA.id && nampihCount === 0) {
                currentSaka = currentSaka - 1;
            }
            if (currentSasih === Sasih_1.Sasih.KAPITU.id && currentSaka === 1914) {
                inSK = false;
            }
            else if (currentSasih === Sasih_1.Sasih.KAPITU.id && currentSaka === 1924) {
                inSK = true;
            }
        }
        switch (currentSaka % 19) {
            case 0:
            case 6:
            case 11:
                if (currentSasih === Sasih_1.Sasih.DESTHA.id && !inSK) {
                    if (currentSaka !== 1925) {
                        nampihCount++;
                    }
                }
                break;
            case 3:
            case 8:
            case 14:
            case 16:
                if (currentSasih === Sasih_1.Sasih.SADHA.id && !inSK) {
                    nampihCount++;
                }
                break;
            case 2:
            case 10:
                if (currentSasih === Sasih_1.Sasih.DESTHA.id && inSK) {
                    nampihCount++;
                }
                break;
            case 4:
                if (currentSasih === Sasih_1.Sasih.KATIGA.id && inSK) {
                    nampihCount++;
                }
                break;
            case 7:
                if (currentSasih === Sasih_1.Sasih.KASA.id && inSK) {
                    nampihCount++;
                }
                break;
            case 13:
                if (currentSasih === Sasih_1.Sasih.KADASA.id && inSK) {
                    nampihCount++;
                }
                break;
            case 15:
                if (currentSasih === Sasih_1.Sasih.KARO.id && inSK) {
                    nampihCount++;
                }
                break;
            case 18:
                if (currentSasih === Sasih_1.Sasih.SADHA.id && inSK) {
                    nampihCount++;
                }
                break;
            default:
                break;
        }
    }
    res[0] = currentSaka;
    res[1] = currentSasih;
    if (dayTotal >= 0) {
        res[2] = nampihCount === 2 ? 1 : 0;
    }
    else {
        res[2] = nampihCount === 1 ? 1 : 0;
    }
    return res;
};
const _F_CALC_SASIH_DAY = (pivot, date) => {
    const res = new Array(3);
    const ptime = pivot.date;
    const dayDiff = _F_DELTA_D(ptime, date);
    const daySkip = Math.ceil(dayDiff / _C_DAY_NGUNARATRI);
    const dayTotal = pivot.sasihDay + dayDiff + daySkip;
    res[0] = _F_MOD(dayTotal, 30);
    res[1] = (res[0] === 0 || res[0] > 15) ? 1 : 0;
    res[2] = (_F_MOD(dayDiff, _C_DAY_NGUNARATRI) === 0) ? 1 : 0;
    res[0] = _F_MOD(res[0], 15);
    res[0] = (res[0] === 0) ? 15 : res[0];
    return res;
};
const _F_CALC_SASIH = (resSasih) => {
    const saka = resSasih[0];
    const sasih = resSasih[1];
    let res = Sasih_1.Sasih.values[sasih];
    if (resSasih[2] === 1) {
        if (res === Sasih_1.Sasih.DESTHA) {
            res = (saka < 1914) ? Sasih_1.Sasih.MALA_DESTHA : Sasih_1.Sasih.NAMPIH_DESTHA;
        }
        else if (res === Sasih_1.Sasih.KATIGA) {
            res = Sasih_1.Sasih.NAMPIH_KATIGA;
        }
        else if (res === Sasih_1.Sasih.KASA) {
            res = Sasih_1.Sasih.NAMPIH_KASA;
        }
        else if (res === Sasih_1.Sasih.KADASA) {
            res = Sasih_1.Sasih.NAMPIH_KADASA;
        }
        else if (res === Sasih_1.Sasih.KARO) {
            res = Sasih_1.Sasih.NAMPIH_KARO;
        }
        else if (res === Sasih_1.Sasih.SADHA) {
            res = (saka < 1914) ? Sasih_1.Sasih.MALA_SADHA : Sasih_1.Sasih.NAMPIH_SADHA;
        }
    }
    return res;
};
const _F_CALC_SASIH_DAY_INFO = (resSasihDay, sasih, saka) => {
    const date = resSasihDay[0];
    const isPangelong = resSasihDay[1] === 1;
    const isNgunaRatri = resSasihDay[2] === 1;
    if (isPangelong) {
        if (date === 15 || (date === 14 && isNgunaRatri)) {
            return SasihDayInfo_1.SasihDayInfo.TILEM;
        }
        else if (date === 14 && sasih === Sasih_1.Sasih.KAPITU && saka === 1921) {
            return SasihDayInfo_1.SasihDayInfo.TILEM;
        }
        else {
            return SasihDayInfo_1.SasihDayInfo.PANGELONG;
        }
    }
    else {
        if (date === 15 || (date === 14 && isNgunaRatri)) {
            return SasihDayInfo_1.SasihDayInfo.PURNAMA;
        }
        else {
            return SasihDayInfo_1.SasihDayInfo.PENANGGAL;
        }
    }
};
const _F_CALC_PRATITHI_S_P = (sasihDay, sasihDayInfo, sasih, date) => {
    let move = 0;
    const isNG = sasihDay.length > 1;
    const day = isNG ? sasihDay[1] : sasihDay[0];
    if (sasihDayInfo.reference === SasihDayInfo_1.SasihDayInfo.PENANGGAL) {
        if (day === 1 && isNG) {
            move = 0;
        }
        else {
            if (day >= 1 && day <= 8) {
                move = day - 1;
            }
            else if (day >= 9 && day <= 13) {
                move = day - 2;
            }
            else if (day === 14) {
                move = 11;
            }
            else if (day === 15) {
                move = 0;
            }
        }
    }
    else {
        if (day === 1 && isNG) {
            const temp = dateAdd(date, 1);
            const nextDay = new BalineseDate(temp);
            if (nextDay.sasih.reference !== sasih.reference) {
                move = -1;
            }
        }
        else {
            move = (day >= 13) ? day - 11 : day - 1;
        }
    }
    const start = PratithiSamutPada_1.PratithiSamutPada.values[sasih.reference.id];
    const newID = _F_MOD(start.id - move, 12);
    return PratithiSamutPada_1.PratithiSamutPada.values[newID];
};
