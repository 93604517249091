"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const dateAdd = require("date-fns/add_days");
const dateDiff = require("date-fns/difference_in_calendar_days");
const BalineseDate_1 = require("./BalineseDate");
const PancaWara_1 = require("./PancaWara");
const Rahinan_1 = require("./Rahinan");
const SaptaWara_1 = require("./SaptaWara");
const Sasih_1 = require("./Sasih");
const SasihDayInfo_1 = require("./SasihDayInfo");
const TriWara_1 = require("./TriWara");
const Wuku_1 = require("./Wuku");
class BalineseDateUtil {
    static filterByDateRange(start, end, filter) {
        const result = [];
        let now = dateAdd(start, 0);
        for (; dateDiff(end, now) >= 0; now = dateAdd(now, 1)) {
            const x = new BalineseDate_1.BalineseDate(now);
            if (this.filterByItem(x, filter)) {
                result.push(x);
            }
        }
        return result;
    }
    static filterByList(list, filter) {
        const result = [];
        for (const item of list) {
            if (this.filterByItem(item, filter)) {
                result.push(item);
            }
        }
        return result;
    }
    static filterByItem(item, filter) {
        if (filter !== undefined) {
            if (!_F_CHECK(filter.wuku, item.wuku)) {
                return false;
            }
            if (!_F_CHECK(filter.ekaWara, item.ekaWara)) {
                return false;
            }
            if (!_F_CHECK(filter.dwiWara, item.dwiWara)) {
                return false;
            }
            if (!_F_CHECK(filter.triWara, item.triWara)) {
                return false;
            }
            if (!_F_CHECK(filter.caturWara, item.caturWara)) {
                return false;
            }
            if (!_F_CHECK(filter.pancaWara, item.pancaWara)) {
                return false;
            }
            if (!_F_CHECK(filter.sadWara, item.sadWara)) {
                return false;
            }
            if (!_F_CHECK(filter.saptaWara, item.saptaWara)) {
                return false;
            }
            if (!_F_CHECK(filter.astaWara, item.astaWara)) {
                return false;
            }
            if (!_F_CHECK(filter.sangaWara, item.sangaWara)) {
                return false;
            }
            if (!_F_CHECK(filter.dasaWara, item.dasaWara)) {
                return false;
            }
            if (!_F_CHECK(filter.ingkel, item.ingkel)) {
                return false;
            }
            if (!_F_CHECK(filter.jejepan, item.jejepan)) {
                return false;
            }
            if (!_F_CHECK(filter.watekAlit, item.watekAlit)) {
                return false;
            }
            if (!_F_CHECK(filter.watekMadya, item.watekMadya)) {
                return false;
            }
            if (!_F_CHECK(filter.lintang, item.lintang)) {
                return false;
            }
            if (!_F_CHECK(filter.pancaSuda, item.pancaSuda)) {
                return false;
            }
            if (!_F_CHECK(filter.pararasan, item.pararasan)) {
                return false;
            }
            if (!_F_CHECK(filter.rakam, item.rakam)) {
                return false;
            }
            if (!_F_CHECK(filter.ekaJalaRsi, item.ekaJalaRsi)) {
                return false;
            }
            if (!_F_CHECK(filter.saka, item.saka)) {
                return false;
            }
            if (!_F_CHECK(filter.sasih, item.sasih)) {
                return false;
            }
            if (!_F_CHECK(filter.pratithiSamutPada, item.pratithiSamutPada)) {
                return false;
            }
            if (!_F_CHECK_SASIH_D(filter.sasihDay, item.sasihDay)) {
                return false;
            }
            if (!_F_CHECK_SASIH_D_I(filter.sasihDayInfo, item.sasihDayInfo)) {
                return false;
            }
        }
        return true;
    }
    static getRahinan(date) {
        return _F_CALC_RAHINAN(date);
    }
}
exports.BalineseDateUtil = BalineseDateUtil;
const _F_ARRAY_CHECK = (a, b) => {
    if (a.length === b.length) {
        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
                return false;
            }
        }
        return true;
    }
    return false;
};
const _F_CHECK = (expectation, reality) => {
    return (expectation !== undefined) ? (expectation === reality) : true;
};
const _F_CHECK_SASIH_D = (expectation, reality) => {
    if (expectation !== undefined) {
        switch (expectation.length) {
            case 1:
                if (reality.length === 1) {
                    return (expectation[0] === reality[0]);
                }
                else if (reality.length === 2) {
                    return (expectation[0] === reality[0]) || (expectation[0] === reality[1]);
                }
                return false;
            case 2:
                return (reality.length === 2) ? _F_ARRAY_CHECK(expectation, reality) : false;
            default:
                return false;
        }
    }
    return true;
};
const _F_CHECK_SASIH_D_I = (expectation, reality) => {
    if (expectation !== undefined) {
        if (expectation === SasihDayInfo_1.SasihDayInfo.PURNAMA || expectation === SasihDayInfo_1.SasihDayInfo.TILEM) {
            return expectation === reality;
        }
        else {
            return expectation === reality.reference;
        }
    }
    return true;
};
const _F_CALC_RAHINAN = (date) => {
    const arr = new Array();
    if (date !== undefined) {
        if (date.triWara === TriWara_1.TriWara.KAJENG && date.pancaWara === PancaWara_1.PancaWara.KLIWON) {
            arr.push(Rahinan_1.Rahinan.KAJENG_KLIWON);
        }
        if (date.saptaWara === SaptaWara_1.SaptaWara.ANGGARA && date.pancaWara === PancaWara_1.PancaWara.KLIWON) {
            arr.push(Rahinan_1.Rahinan.ANGGARA_KASIH);
        }
        else if (date.saptaWara === SaptaWara_1.SaptaWara.BUDA && date.pancaWara === PancaWara_1.PancaWara.WAGE) {
            arr.push(Rahinan_1.Rahinan.BUDA_CEMENG);
        }
        if (date.wuku === Wuku_1.Wuku.SINTA) {
            if (date.saptaWara === SaptaWara_1.SaptaWara.REDITE) {
                arr.push(Rahinan_1.Rahinan.BANYU_PINARUH);
            }
            else if (date.saptaWara === SaptaWara_1.SaptaWara.SOMA) {
                arr.push(Rahinan_1.Rahinan.SOMA_RIBEK);
            }
            else if (date.saptaWara === SaptaWara_1.SaptaWara.ANGGARA) {
                arr.push(Rahinan_1.Rahinan.SABUH_EMAS);
            }
            else if (date.saptaWara === SaptaWara_1.SaptaWara.BUDA) {
                arr.push(Rahinan_1.Rahinan.PAGER_WESI);
            }
        }
        else if (date.wuku === Wuku_1.Wuku.LANDEP) {
            if (date.saptaWara === SaptaWara_1.SaptaWara.SANISCARA) {
                arr.push(Rahinan_1.Rahinan.TUMPEK_LANDEP);
            }
        }
        else if (date.wuku === Wuku_1.Wuku.WARIGA) {
            if (date.saptaWara === SaptaWara_1.SaptaWara.SANISCARA) {
                arr.push(Rahinan_1.Rahinan.TUMPEK_UDUH);
            }
        }
        else if (date.wuku === Wuku_1.Wuku.SUNGSANG) {
            if (date.saptaWara === SaptaWara_1.SaptaWara.WRASPATI) {
                arr.push(Rahinan_1.Rahinan.SUGIHAN_JAWA);
            }
            else if (date.saptaWara === SaptaWara_1.SaptaWara.SUKRA) {
                arr.push(Rahinan_1.Rahinan.SUGIHAN_BALI);
            }
        }
        else if (date.wuku === Wuku_1.Wuku.DUNGULAN) {
            if (date.saptaWara === SaptaWara_1.SaptaWara.REDITE) {
                arr.push(Rahinan_1.Rahinan.PENYEKEBAN_GALUNGAN);
            }
            else if (date.saptaWara === SaptaWara_1.SaptaWara.SOMA) {
                arr.push(Rahinan_1.Rahinan.PENYAJAN_GALUNGAN);
            }
            else if (date.saptaWara === SaptaWara_1.SaptaWara.ANGGARA) {
                arr.push(Rahinan_1.Rahinan.PENAMPAHAN_GALUNGAN);
            }
            else if (date.saptaWara === SaptaWara_1.SaptaWara.BUDA) {
                arr.push(Rahinan_1.Rahinan.GALUNGAN);
            }
            else if (date.saptaWara === SaptaWara_1.SaptaWara.WRASPATI) {
                arr.push(Rahinan_1.Rahinan.MANIS_GALUNGAN);
            }
            else if (date.saptaWara === SaptaWara_1.SaptaWara.SANISCARA) {
                arr.push(Rahinan_1.Rahinan.PEMARIDAN_GURU);
            }
        }
        else if (date.wuku === Wuku_1.Wuku.KUNINGAN) {
            if (date.saptaWara === SaptaWara_1.SaptaWara.REDITE) {
                arr.push(Rahinan_1.Rahinan.ULIHAN);
            }
            else if (date.saptaWara === SaptaWara_1.SaptaWara.SOMA) {
                arr.push(Rahinan_1.Rahinan.PEMACEKAN_AGUNG);
            }
            else if (date.saptaWara === SaptaWara_1.SaptaWara.SUKRA) {
                arr.push(Rahinan_1.Rahinan.PENAMPAHAN_KUNINGAN);
            }
            else if (date.saptaWara === SaptaWara_1.SaptaWara.SANISCARA) {
                arr.push(Rahinan_1.Rahinan.KUNINGAN);
            }
        }
        else if (date.wuku === Wuku_1.Wuku.PAHANG) {
            if (date.saptaWara === SaptaWara_1.SaptaWara.BUDA) {
                arr.push(Rahinan_1.Rahinan.PEGAT_UWAKAN);
            }
        }
        else if (date.wuku === Wuku_1.Wuku.UYE) {
            if (date.saptaWara === SaptaWara_1.SaptaWara.SANISCARA) {
                arr.push(Rahinan_1.Rahinan.TUMPEK_KANDANG);
            }
        }
        else if (date.wuku === Wuku_1.Wuku.WAYANG) {
            if (date.saptaWara === SaptaWara_1.SaptaWara.SANISCARA) {
                arr.push(Rahinan_1.Rahinan.TUMPEK_WAYANG);
            }
        }
        else if (date.wuku === Wuku_1.Wuku.WATUGUNUNG) {
            if (date.saptaWara === SaptaWara_1.SaptaWara.SANISCARA) {
                arr.push(Rahinan_1.Rahinan.SARASWATI);
            }
        }
        const temp = date.date;
        const n1Day = new BalineseDate_1.BalineseDate(dateAdd(temp, 1));
        const b1Day = new BalineseDate_1.BalineseDate(dateAdd(temp, -1));
        const b2Day = new BalineseDate_1.BalineseDate(dateAdd(temp, -2));
        if (n1Day.sasih === Sasih_1.Sasih.KAPITU && n1Day.sasihDayInfo === SasihDayInfo_1.SasihDayInfo.TILEM) {
            arr.push(Rahinan_1.Rahinan.SIWA_RATRI);
        }
        else if (date.saka < n1Day.saka) {
            arr.push(Rahinan_1.Rahinan.TAWUR_AGUNG_KASANGA);
        }
        else if (b1Day.saka < date.saka) {
            arr.push(Rahinan_1.Rahinan.NYEPI);
        }
        else if (b2Day.saka < date.saka && b1Day.saka === date.saka) {
            arr.push(Rahinan_1.Rahinan.NGEMBAK_GENI);
        }
        if (date.sasihDayInfo === SasihDayInfo_1.SasihDayInfo.PURNAMA) {
            arr.push(Rahinan_1.Rahinan.PURNAMA);
        }
        else if (date.sasihDayInfo === SasihDayInfo_1.SasihDayInfo.TILEM) {
            arr.push(Rahinan_1.Rahinan.TILEM);
        }
    }
    Object.freeze(arr);
    return arr;
};
