"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class AstaWara {
    constructor(id, urip, name) {
        this.id = id;
        this.urip = urip;
        this.name = name;
        Object.freeze(this);
    }
    static get values() { return _C_VAL; }
    static get SRI() { return _C_VAL[0]; }
    static get INDRA() { return _C_VAL[1]; }
    static get GURU() { return _C_VAL[2]; }
    static get YAMA() { return _C_VAL[3]; }
    static get LUDRA() { return _C_VAL[4]; }
    static get BRAHMA() { return _C_VAL[5]; }
    static get KALA() { return _C_VAL[6]; }
    static get UMA() { return _C_VAL[7]; }
}
exports.AstaWara = AstaWara;
const _C_VAL = [
    new AstaWara(0, 6, "Sri"),
    new AstaWara(1, 5, "Indra"),
    new AstaWara(2, 8, "Guru"),
    new AstaWara(3, 9, "Yama"),
    new AstaWara(4, 3, "Ludra"),
    new AstaWara(5, 7, "Brahma"),
    new AstaWara(6, 1, "Kala"),
    new AstaWara(7, 4, "Uma"),
];
Object.freeze(_C_VAL);
