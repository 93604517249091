"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class PancaSuda {
    constructor(id, name) {
        this.id = id;
        this.name = name;
        Object.freeze(this);
    }
    static get values() { return _C_VAL; }
    static get LEBU_KATIUB_ANGIN() { return _C_VAL[0]; }
    static get WISESA_SEGARA() { return _C_VAL[1]; }
    static get TUNGGAK_SEMI() { return _C_VAL[2]; }
    static get SATRIA_WIBAWA() { return _C_VAL[3]; }
    static get SUMUR_SINABA() { return _C_VAL[4]; }
    static get SATRIA_WIRANG() { return _C_VAL[5]; }
    static get BUMI_KAPETAK() { return _C_VAL[6]; }
}
exports.PancaSuda = PancaSuda;
const _C_VAL = [
    new PancaSuda(0, "Lebu Katiup Angin"),
    new PancaSuda(1, "Wisesa Segara"),
    new PancaSuda(2, "Tunggak Semi"),
    new PancaSuda(3, "Satria Wibawa"),
    new PancaSuda(4, "Sumur Sinaba"),
    new PancaSuda(5, "Satria Wirang"),
    new PancaSuda(6, "Bumi Kapetak"),
];
Object.freeze(_C_VAL);
