"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Sasih_1 = require("../Sasih");
class Pivot {
    constructor(year, month, dayOfMonth, pawukonDay, sasihDay, ngunaRatriDay, saka, sasih, isNampihSasih) {
        this.pawukonDay = pawukonDay;
        this.sasihDay = sasihDay;
        this.ngunaRatriDay = ngunaRatriDay;
        this.saka = saka;
        this.sasih = sasih;
        this.isNampihSasih = isNampihSasih;
        this.date = new Date(year, month, dayOfMonth);
        Object.freeze(this.date);
        Object.freeze(this);
    }
    static get PIVOT_1971() { return _C_VAL[0]; }
    static get PIVOT_2000() { return _C_VAL[1]; }
}
exports.Pivot = Pivot;
const _C_VAL = [
    new Pivot(1971, 0, 27, 3, 0, 0, 1892, Sasih_1.Sasih.KAPITU, false),
    new Pivot(2000, 0, 18, 86, 12, 0, 1921, Sasih_1.Sasih.KAPITU, false),
];
Object.freeze(_C_VAL);
